import type {
  ArticleItemFragment,
  EventItemFragment,
  GroupActivityItemFragment,
  ServiceItemFragment,
  ServiceResource,
  SubscriptionItemFragment,
  ValueCardItemFragment,
} from "@/generated/client.generated";

export enum BookingType {
  GRUPPETIME = "GRUPPETIME",
  SQUASH = "SQUASH",
  CAGEBALL = "CAGEBALL",
  GOLFSIMULATOR = "GOLFSIMULATOR",
  PT = "PT",
  VEILEDNING = "VEILEDNING",
  TRENINGSTESTER = "TRENINGSTESTER",
  BARNEPASS = "BARNEPASS",
  KLIPPEKORT = "KLIPPEKORT",
  ACTIVITIES = "ACTIVITIES",
  EVENTS = "EVENTS",
  BEHANDLER = "BEHANDLER",
  INSPIRASJON = "INSPIRASJON",
  PTPAKKER = "PTPAKKER",
}

export enum BookingHeaderRowFilterTypes {
  WEEKDAYSROW = "WEEKDAYSROW",
  TIMEOFDAY = "TIMEOFDAY",
  TIMEPERIOD = "TIMEPERIOD",
  CATEGORY = "CATEGORY",
  CENTER = "CENTER",
  ROLE = "ROLE",
  SPECIALITY = "SPECIALITY",
  GUIDANCE = "GUIDANCE",
  TRENINGSTESTER = "TRENINGSTESTER",
  PTDURATION = "PTDURATION",
  PTLEVEL = "PTLEVEL",
  PTTYPE = "PTTYPE",
  TAG = "TAG",
  CLIPCOUNT = "CLIPCOUNT",
}

export type KeyValue = {
  key: string;
  value: string;
};

export type Service = {
  centerRef: {
    name: string;
    id: string;
  };
  selectableResources: Array<ServiceResource>;
  start: string;
  end: string;
  priceAmount: number;
  id: string;
};

export type SelectedResource = {
  bookingType: BookingType;
  startTime: string;
  court: ServiceResource;
  centerId: string;
};

export const mapBookingTypeCMSCategoryName = new Map<BookingType, string>([
  [BookingType.BARNEPASS, "BARNEPASS"],
  [BookingType.CAGEBALL, "CAGEBALLBANE"],
  [BookingType.GOLFSIMULATOR, "GOLFSIMULATOR"],
  [BookingType.SQUASH, "SQUASHBANE"],
  [BookingType.VEILEDNING, "VEILEDNING"],
  [BookingType.TRENINGSTESTER, "TRENINGSTESTER"],
  [BookingType.PTPAKKER, "PTPAKKER"],
]);

export const bookingTypeToServiceId = (bookingType: BookingType) => {
  switch (bookingType) {
    case BookingType.CAGEBALL: {
      return "711";
    }
    case BookingType.GOLFSIMULATOR: {
      return "39484";
    }
    case BookingType.SQUASH: {
      return "352";
    }
    default: {
      return "0";
    }
  }
};

export const serviceIdToBookingType = (serviceId: string | undefined) => {
  switch (serviceId) {
    case "711": {
      return BookingType.CAGEBALL;
    }
    case "39484": {
      return BookingType.GOLFSIMULATOR;
    }
    case "352": {
      return BookingType.SQUASH;
    }
    //LACTATETEST
    case "27586": {
      return BookingType.TRENINGSTESTER;
    }
    //O2MAXTEST
    case "27583": {
      return BookingType.TRENINGSTESTER;
    }
    //LACTATEANDO2MAXTEST
    case "27585": {
      return BookingType.TRENINGSTESTER;
    }
    //Kroppsanalyse InBody 30 min
    case "5432": {
      return BookingType.TRENINGSTESTER;
    }
    //GUIDANCE_30
    case "32555": {
      return BookingType.VEILEDNING;
    }
    //GUIDANCE_50
    case "5757": {
      return BookingType.VEILEDNING;
    }
    //GUIDANCE_TEEN_50
    case "32554": {
      return BookingType.VEILEDNING;
    }
    default: {
      return null;
    }
  }
};

export type ItemAlias =
  | ServiceItemFragment
  | GroupActivityItemFragment
  | ValueCardItemFragment
  | ArticleItemFragment
  | SubscriptionItemFragment
  | EventItemFragment;
