"use client";
import { Button } from "@/components/base/button";
import { PageError } from "@/components/error/page-error";

// biome-ignore lint/suspicious/noShadowRestrictedNames: nextjs naming convention
export default function Error() {
  const handleReload = () => {
    //fjerner query parms ved klikk på knappen
    const currentPath = window.location.pathname;
    window.location.href = currentPath;
  };

  return (
    <>
      <PageError heading="Det skjedde en feil" />
      <Button buttonType="secondary" onClick={handleReload}>
        Last siden inn igjen
      </Button>
    </>
  );
}
