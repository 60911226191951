export const isExternalLink = (href?: string) => {
  if (href === undefined) {
    return false;
  }
  const urlIsAbsolute = href.indexOf("http://") !== -1 || href?.indexOf("https://") !== -1;

  const isExternal =
    urlIsAbsolute && !href?.includes("3t.no") && !href?.includes("localhost") && !href?.includes("tttno-dev");
  return isExternal;
};
